@import '../tools/_Mixin.scss';
@import '../tools/_Variables.scss';

.partenaires {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__HCC {
        width: 70px;
        height: 70px;

        background: url('../images/logo/HCC.png') no-repeat;
        background-size: contain;
        background-position: center;
    }

    &__atlande {
        width: 70px;
        height: 70px;

        background: url('../images/logo/atlande.png') no-repeat;
        background-size: contain;
    }

    &__reporterre {
        width: 105px;
        height: 70px;

        background: url('../images/logo/reporterre.png') no-repeat;
        background-size: contain;
    }

    &__geg {
        width: 64px;
        height: 65px;

        background: url('../images/logo/geg.png') no-repeat;
        background-size: contain;
    }

    &__irsem {
        width: 78px;
        height: 43px;

        background: url('../images/logo/irsem.png') no-repeat;
        background-size: contain;
    }

    &__pnr {
        width: 78px;
        height: 78px;

        background: url('../images/logo/pnr.png') no-repeat;
        background-size: contain;
    }

    &__asialyst {
        width: 60px;
        height: 60px;

        background: url('../images/logo/asialyst.png') no-repeat;
        background-size: contain;
    }
}