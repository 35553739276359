@import '../tools/_Mixin.scss';
@import '../tools/_Variables.scss';

.showMap {
    position: relative;
    display: none;

    &__box {
        position: fixed;
        z-index: 999 !important;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        display: flex;
        align-items: center;
        justify-content: center;
        
        background: rgba(12,12,12,0.5);

        cursor: pointer;

        &__img {
            max-width: 90%;
            max-height: 90%;
        }
    }
    
    &.is-active {
        display: block;
    }
}
