@import '../tools/_Mixin.scss';
@import '../tools/_Variables.scss';

.accueil {
    &__illustration {
        &__nuage1 {
            @include nuage;
            width: 107px;
            height: 63px;           
            top: 300px;                
            animation-delay: 0s;
            animation-duration: 30s;
        }

        &__nuage2 {
            @include nuage;            
            top: 200px;
            animation-delay: 8s;
            animation-duration: 20s;
        }

        &__nuage3 {
            @include nuage;
            top: 550px;
            animation-delay: 4s;
            animation-duration: 15s;
        }
    }
}

@media (max-width: 1024px) { 
    .accueil__illustration {
        &__nuage1 {
            top: 250px;
        }

        &__nuage2 {
            top: 150px;
        }

        &__nuage3 {
            top: 400px;
        }
    }    
}

@media (max-width: 375px) { 
    .accueil__illustration {
        &__nuage1 {
            display: none;
        }

        &__nuage2 {
            display: none;
        }

        &__nuage3 {
            display: none;
        }
    }    
}

