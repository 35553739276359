@import '../tools/_Mixin.scss';
@import '../tools/_Variables.scss';

/*--------------------------------------------------*\
                prestations media pc 
\*--------------------------------------------------*/

.prestations {
    &__container { margin-top: 250px; }
    
    &__title {
        @include sectionTitle;
        margin: 0 0 100px 12px;
        border-top: 55px solid transparent;

        &::before {
            width: 926px;
            height: 167px;

            top: -80px;
            left: -20px;
            
            background: url(../images/titre/prestations.svg) no-repeat;
        }
    }

    &__banner {
        display: grid;
        grid-template-columns: 320px 1fr 320px;
        grid-template-rows: repeat(3, 215px);
        gap: 15px;
 
        &__card {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            &.card-1 { grid-column: 1; grid-row: 1;}
            &.card-2 { grid-column: 1; grid-row: 2;}
            &.card-3 { grid-column: 1; grid-row: 3;}
            &.card-4 { grid-column: 3; grid-row: 1;}
            &.card-5 { grid-column: 3; grid-row: 2;}
            &.card-6 { grid-column: 3; grid-row: 3;}

            &__title {
                margin-top: 10px;
                font-size: 29px;
                font-weight: 700;
                color: $purple;
            }

            &__description {   
                margin-top: 5px;
                font-size: 17px;
                line-height: 22px;
                color: $gray;
            }
        
            &__icon {
                width: 100px;
                height: 100px;
                transition-duration: 0.2s;

                &.icon-1 {
                    position: relative;
                    background: url(../images/icon/worldwide.svg) no-repeat;

                    &::after {
                        @include methodeIcon;
                        background: url(../images/icon/worldwide-hover.svg) no-repeat;
                    }
                    
                    &:hover {
                         &::after { opacity: 1; }
                    }
                }

                &.icon-2 {
                    position: relative;
                    background: url(../images/icon/travel-map.svg) no-repeat;

                    &::after {
                        @include methodeIcon;
                        background: url(../images/icon/travel-map-hover.svg) no-repeat;
                    }
                    
                    &:hover {
                         &::after { opacity: 1; }
                    }
                }

                &.icon-3 {
                    position: relative;
                    background: url(../images/icon/town.svg) no-repeat;
                    
                    &::after {
                        @include methodeIcon;
                        background: url(../images/icon/town-hover.svg) no-repeat;
                    }
                    
                    &:hover {
                         &::after { opacity: 1; }
                    }
                }

                &.icon-4 {
                    position: relative;
                    background: url(../images/icon/statistics.svg) no-repeat;
                    
                    &::after {
                        @include methodeIcon;
                        background: url(../images/icon/statistics-hover.svg) no-repeat;
                    }
                    
                    &:hover {
                         &::after { opacity: 1; }
                    }
                }

                &.icon-5 {
                    position: relative;
                    background: url(../images/icon/infographic.svg) no-repeat;
                    
                    &::after {
                        @include methodeIcon;
                        background: url(../images/icon/infographic-hover.svg) no-repeat;
                    }
                    
                    &:hover {
                         &::after { opacity: 1; }
                    }
                }

                &.icon-6 {
                    position: relative;
                    background: url(../images/icon/treasure-map.svg) no-repeat;
                    
                    &::after {
                        @include methodeIcon;
                        background: url(../images/icon/treasure-map-hover.svg) no-repeat;
                    }
                    
                    &:hover {
                         &::after { opacity: 1; }
                    }
                }
            }  
        }

        &__img {
            width: 100%;
            height: 690px;
            grid-column: 2/2;
            grid-row: 1/4;
            background: url(../images/world.png) no-repeat;
            background-size: contain;
            background-position: center;
        }
    }
}

/*--------------------------------------------------*\
                prestations  media tablette 
\*--------------------------------------------------*/

@media (max-width: 1024px) {
    .prestations {
        &__container { margin-top: 150px; }

        &__title {
            margin: 0 0 20px 15px;
            border-top: 15px solid transparent;
            font-size: 46px;

            &::before {
                width: 513px;
                height: 90px;
                top: -26px;
                background-size: contain;
            }
        }

        &__banner {
            grid-template-columns: 1fr;
            grid-template-rows: 510px repeat(6, 100px);
            gap: 30px;
    
            &__card {
                display: grid;
                grid-template-columns: 68% 100px;
                grid-template-rows: 1fr 1fr;
                align-items: center;
                justify-content: center;
                text-align: start;

                &.card-1 { grid-column: 1; grid-row: 2;}
                &.card-2 { grid-column: 1; grid-row: 3;}
                &.card-3 { grid-column: 1; grid-row: 4;}
                &.card-4 { grid-column: 1; grid-row: 5;}
                &.card-5 { grid-column: 1; grid-row: 6;}
                &.card-6 { grid-column: 1; grid-row: 7;}

                &__title {
                    grid-column: 1;
                    grid-row: 1;
                }

                &__description {
                    grid-column: 1;
                    grid-row: 2;
                }
            
                &__icon {
                    width: 100%;
                    height: 100%;
                    grid-column: 2;
                    grid-row: 1/3;
                }  
            }

            &__img {
                height: 510px;
                margin-top: 30px;
                grid-column: 1;
                grid-row: 1;
            }
        }
    }
}

/*--------------------------------------------------*\
            prestations media telephone 768px
\*--------------------------------------------------*/

@media (max-width: 768px) {
    .prestations {
        &__container { margin-top: 150px; }
        
        &__title {
            margin: 0 0 20px 8px;
            font-size: 39px;

            &::before {
                width: 405px;
                height: 70px;
                top: -20px;
                left: -12px;
            }
        }

        &__banner {
            grid-template-rows: 390px repeat(6, 100px);
            gap: 25px;
    
            &__card { margin-top: 0; }

            &__img { 
                margin-top: 0;
                height: 390px; 
            }
        }
    }
}

/*--------------------------------------------------*\
            prestations media telephone 375px
\*--------------------------------------------------*/

@media (max-width: 375px) {
    .prestations {
        &__container { margin-top: 100px; }
        
        &__title {
            font-size: 29px;

            &::before {
                width: 305px;
                height: 60px;
                top: -19px;
            }
        }

        &__banner {
            grid-template-rows: 270px repeat(6, 70px);
            gap: 25px;
    
            &__card { 
                margin-top: 0; 
                grid-template-columns: 68% 70px;
                
                &__title { font-size: 20px; }

                &__description {
                    margin-top: 5px;
                    font-size: 14px;
                    line-height: 19px;
                }
                
            }
            
            &__img { 
                margin-top: 0;
                height: 270px; 
            }
        }
    }
}