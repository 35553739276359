@import '../tools/_Mixin.scss';
@import '../tools/_Variables.scss';
@import '../tools/_Keyframes.scss';

/*--------------------------------------------------*\
                portfolio media pc
\*--------------------------------------------------*/

.portfolio {
    &__container {
        position: relative;
        margin-top: 350px;
    }
    
    &__title {
        @include sectionTitle;
        margin: 0 13px 100px 0;
        border-top: 25px solid transparent;

        &::before {
            z-index: 10;
            width: 1104px;
            height: 164px;

            top: -45px;
            left: -20px;

            background: url(../images/titre/portfolio.svg) no-repeat;
            background-size: contain;
        }
    }

    &__banner {
        display: flex;
        justify-content: center;

        &__column {
            width: 20%;
            margin-right: 10px;

            &__img {
                position: relative;
                margin-bottom: 6px;

                & > img {
                    width: 100%;
                    height: auto;
                }

                &::before {
                    position: absolute;
                    z-index: 2;
                    content: '';
                    width: 100%;
                    height: calc(100% - 3px);
                   
                    bottom: 3px;
                    left: 0;

                    background: linear-gradient(to top, rgba(0,0,0, 0.8) 0%, transparent 50%) no-repeat left bottom / 100% 0%;

                    cursor: pointer;
                }
                           
                &__details {

                    &__title {
                        position: absolute;
                        z-index: 5;
                        margin-left: 15px;

                        bottom: 25px;
                        
                        opacity: 0;
                        
                        color: $white;
                        line-height: 1;
                        font-size: 18px;
                        font-weight: 700;

                        transition-duration: $transitionDuration;
                    }
                        
                    &__info {
                        position: absolute;
                        z-index: 5;
                        margin: 5px 0 0 15px;
                        
                        bottom: 10px;
                        
                        opacity: 0;

                        color: $white;
                        line-height: 1.2;
                        font-size: 12px;
                        font-weight: 600;

                        transition-duration: $transitionDuration;
                    }
                }
                
                &:hover {
                    &::before{
                        animation: imgHover 0.3s both;
                    }
                }

                &:hover .portfolio__banner__column__img__details__title,
                &:hover .portfolio__banner__column__img__details__info {
                    opacity: 1;
                } 
            }            
        }
        
        &__bck {
            position: absolute;
            z-index: -1;
    
            top: 155px;
            bottom: -5px;
            left: 0px;
            right: 0px;
            
            background-color: $purple;
    
            &::before {
                position: absolute;
                content: '';
                width: 100%;
                height: 175px;
                
                top: -95px;
                left: 0;
                
                background-color: $white;
    
                transform-origin: 0, 0;
                transform: skewY(-5deg);
            }
    
            &::after {
                position: absolute;
                content: '';
                width: 100%;
                height: 175px;
                
                bottom: -75px;
                left: 0;
                
                background-color: $white;
                
                transform-origin: 0, 0;
                transform: skewY(-5deg);
            }
        }
    }
}

/*--------------------------------------------------*\
                portfolio media tablette 
\*--------------------------------------------------*/

@media (max-width: 1024px) {
    .portfolio {
        &__container { margin-top: 300px; }

        &__title {
            margin-left: 8px;
            border-top: 10px solid transparent;

            font-size: 46px;
    
            &::before {
                width: 428x;
                height: 100px;
    
                top: -20px;
                left: -12px;
                background-size: contain;
            }
        }
    
        &__banner {
            &__column { width: 26.6%; }

            &__bck {    
                top: 114px;
                bottom: 21px;
                left: 0;
                right: 0;
            }
        }
    }
}

/*--------------------------------------------------*\
            portfolio media telephone 768px
\*--------------------------------------------------*/

@media (max-width: 768px) {
    .portfolio {
        &__container { margin-top: 150px; }

        &__title {
            margin-bottom: 100px;
            margin-left: 8px;

            font-size: 39px;
    
            &::before {
                width: 340x;
                height: 75px;
    
                top: -20px;
                left: -12px;
            }
        }

        &__banner {
            &__column__img {
                &:before { display: none; }
                &__details { display: none; }
            }
                
            &__bck { 
                top: 100px;
                bottom: 5px;
            }
        }
    }
}

/*--------------------------------------------------*\
            portfolio media telephone 375px
\*--------------------------------------------------*/

@media (max-width: 375px) {
    .portfolio {
        &__container { margin-top: 80px; }

        &__title {
            margin-bottom: 60px;
            font-size: 29px;
        }

        &__banner {
            &__column { 
                width: 22%;
                margin-right: 4px;

                &__img { margin-bottom: 0; }
            }
            &__bck { 
                top: 38px;
                bottom: -24px;
            }
        }
    }
}