@import '../tools/_Mixin.scss';
@import '../tools/_Variables.scss';

.showAttributions {
    display: none;

    &__box {
        position: fixed;
        z-index: 999 !important;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        display: flex;
        align-items: center;
        justify-content: center;
        
        background: rgba(12,12,12,0.5);

        cursor: pointer;

        &__links {
            max-width: 90%;
            max-height: 90%;
            padding: 100px;
            background-color: $white;

            & > ul {
                list-style: none;

                & > li {
                    margin-left: 50px;
                }
            }
        }
    }
    
    &.is-active {
        display: block;
    }
}

@media (max-width: 375px) {
    .showAttributions {  
        &__box {    
            &__links {
                font-size: 14px;
                padding: 10px;
            }
        }
    }
}