@import '../tools/_Mixin.scss';
@import '../tools/_Variables.scss';


/*--------------------------------------------------*\
                  nav menu media pc
\*--------------------------------------------------*/

.header__menu { display : none; }

.header {
  &__container {
    position: absolute;
    z-index: 999;
    width: 100%;
    margin: 0 auto;
      
    display: flex;
    right: 0;
    left: 0;
  }

  &__nav {
    padding-top: 20px;

    transition: all .8s cubic-bezier(0.86, 0, 0.07, 1);
    
    &__links { 
      display: flex;
      justify-content: space-between;
      list-style-type: none;

      &__link {
        margin-right: 40px;
              
        background: linear-gradient(to top, rgb(0, 0, 0) -1px, rgb(0, 0, 0) 0px, transparent 1px) no-repeat left bottom / 0 100%;
              
        color: $black;
        font-size: 1.125em;
        font-weight: 400;       
        text-decoration: none;
    
        transition-duration: 0.3s;
      }
    }
  }
}

/*--------------------------------------------------*\
                  nav menu media tablette 
\*--------------------------------------------------*/

@media (max-width: 1024px) { 
  .header__nav {
      display:none;
  }

  .header__menu {
    display: block;
    
    &__btn {
      position: relative;
      z-index: 50;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      margin-top: 10px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &__burger {
        width: 30px;
        height: 2px;
        background: $black;
        border-radius: 1px;
        transition: all 0.3s ease-in-out;

        &:before,
        &:after {
          content: '';
          position: absolute;
          width: 30px;
          height: 2px;
          background: $black;
          border-radius: 1px;
          transition: all 0.3s ease-in-out;
        }

        &:before {
          transform: translateY(-8px);
        }

        &:after {
          transform: translateY(8px);
        }
      }

      &.active {
        transform: translateX(-80px);
      }

      &.active .header__menu__btn__burger {
        background: transparent;
      }
      
      &.active .header__menu__btn__burger::before {
        background: $white;
        transform: rotate(45deg);
      }
  
      &.active .header__menu__btn__burger::after{
        background: $white;
        transform: rotate(-45deg);
      }
    }

    &__block {
      position: absolute;
      z-index: 10;
      width: 300px;
      height: 100vh;
      top: 0;
      left: -300px;
      background: transparent;
      transition: all 0.3s ease-in-out;
      
      &.active {
        display: block;
        left: 0;
        background: $purple;
        box-shadow: 0 0 6px $black;
      }

      &__links {
        display: flex;
        height: 100vh;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        list-style-type: none;

        &__link {
          display: block; 
          margin-top: 60px;

          background: linear-gradient(to top, rgb(251, 251, 251) -1px, rgb(251, 251, 251) 0px, transparent 1px) no-repeat left bottom / 0 100%;
                    
          color: $white;
          font-size: 1.125em;
          font-weight: 400;       
          text-decoration: none;
          
          transition-duration: 0.3s;
        }
      }
    }
  }
}

/*--------------------------------------------------*\
              nav menu media telephone 768px
\*--------------------------------------------------*/
@media (max-width: 768px) { 
  .header__menu {
    &__btn {
      &.active { transform: translateX(-40px); }
    }
  }
}

/*--------------------------------------------------*\
              nav menu media telephone 768px
\*--------------------------------------------------*/
@media (max-width: 375px) { 
  .header__menu {
    &__btn {
      &.active { transform: translateX(0px); }
    }
    &__block {

      &__links {

        &__link {
          margin-top: 30px;
        }
      }
    }
  }
}