/*--------------------------------------------------*\
                    anim nuage
\*--------------------------------------------------*/

@keyframes animNuage {
    from {right: -110px;}
    to {right: 50vw;}
}

@media (max-width: 1024px) {
    @keyframes animNuage {
        from {right: -110px;}
        to {right: 100vw;}
    }
}

/*--------------------------------------------------*\
                    anim nav
\*--------------------------------------------------*/

@keyframes enter {
    from {
      background-size: 0% 100%;
    }
    100% {
      background-size: 100% 100%;
    }
}
    
@keyframes leave {
    from {
        background-size: 100% 100%;
        background-position: bottom right;
    }
    100% {
        background-size: 0% 100%;
        background-position: bottom right;
    }
}

/*--------------------------------------------------*\
                    anim portfolio 
\*--------------------------------------------------*/

@keyframes imgHover {
    from {
        background-size: 100% 0%;
        background-position: bottom left;
    }
    100% {
        background-size: 100% 100%;
        background-position: bottom left;
    }
}