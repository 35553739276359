@mixin sectionTitle {
        position: relative;
        color: $purple;
        font-size: 60px;
        font-weight: 700;
    
        &::before {
            position: absolute;
            z-index: -1;
            content: '';
            background-size: contain;
        }
}

@mixin nuage {
    position: absolute;
    width: 61px;
    height: 36px;

    right: -110px;

    background: url('../images/nuage-1.png') no-repeat;
    background-size: contain;
    
    animation-name: animNuage;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    @media (max-width: 1024px) {
        &__nuage1,
        &__nuage2,
        &__nuage3 {
            right: -400px;
        }
    }
}

@mixin methodeIcon {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: contain;
    opacity: 0;
}