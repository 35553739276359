@import '../tools/_Mixin.scss';
@import '../tools/_Variables.scss';

/*--------------------------------------------------*\
                    media pc
\*--------------------------------------------------*/

.accueil {
    &__container {
        position: relative;
        
        display: grid;
        grid-template-columns: 48% 44%;
        gap: 8%;
        
        align-items: center;
        justify-content: center;
    }

    &__card {   
        display: flex; 
        flex-direction: column;  
        justify-content: space-between; 
        padding-top: 170px;
        
        &__title {
            font-size: 60px;
            font-weight: 700;
        }

        &__subtitle {
            margin-top: 10px;
            
            font-size: 44px;
            font-weight: 400;
        }

        &__description {
            margin-top: 135px;
            
            font-size: 20px;
            font-weight: 400;
            line-height: 35px;
            color: #646464;
        }

        &__btn {
            margin: 80px 0 150px 0;

            &__anchor {
                width: 180px;
                height: 46px;
                padding: 10px 25px;
                text-decoration: none;
                
                border: 0 none;
                border-radius: 10px;
                background-color: $purple;
                box-shadow: none;
                cursor: pointer;
                outline: none;
                
                font-size: 20px;
                font-weight: 500;
                color: $white;
                
                transition-duration: 0.3s;

                &:hover {
                    border: 1px solid $purple;
                    background-color: $white;
                    color: $purple;
                }
            }
        }
    }

    &__illustration {
        flex-basis: 43%;
        height: auto;
        margin-top: -40px;
        grid-column: 2;
        
        
        &__img {
            width: 100%;
            height: auto;
        }
        
        &__rect {
            position: absolute;
            z-index: -1;
            content: '';
            width: 39%;
            height: 950px;
            
            top: 0;
            right:0;
            
            background: url('../images/cercle.png') no-repeat;
            background-size: 100%;
        }
    }
}

/*--------------------------------------------------*\
                    media tablette 
\*--------------------------------------------------*/

@media (max-width: 1024px) { 
    .accueil {
        &__container {      
            display: flex;
            flex-direction: column-reverse;
    
            align-items: center;
            justify-content: center;
        }

        &__card {   
            text-align: center;
            padding-top: 0;
            
            &__title { margin-top: 80px; }
    
            &__subtitle { margin-top: 0; }
    
            &__description {
                max-width: 765px;
                margin-top: 120px;
            }

            &__btn {
                margin-top: 70px;
                margin-bottom: 80px;
            }
        }

        &__illustration {
            position: relative;
            display: flex;
            justify-content: center;
            margin-top: 40px;

            &::after {
                 width: 0;
            }

            &__img {
                max-width: 96%;                
            }

            &__rect {
                position: absolute;
                z-index: -1;
                width: 139.46%;
                height: 72%;
                
                top: 17%;
                left: -100px;
                right: 0;

                background: $purple no-repeat;
                transition: all 0.3s ease-in-out;

                &.active {
                    transform: translateX(-1000px);
                  }
            }
        }
    }
}

/*--------------------------------------------------*\
                    media telephone 768px
\*--------------------------------------------------*/

@media (max-width: 768px) {
    .accueil {    
        &__illustration { 
            &__img { max-width: 81%; }
        }
    }
}

/*--------------------------------------------------*\
                    media telephone 375px
\*--------------------------------------------------*/

@media (max-width: 375px) {
    .accueil {    
        &__card {   
            
            &__title {
                margin-top: 25px;
                font-size: 30px;
            }
    
            &__subtitle { font-size: 24px; }
    
            &__description {
                margin-top: 60px;
                font-size: 16px;
                line-height: 25px;
            }
    
            &__btn {
                margin: 56px 0 10px 0;
                &__anchor { font-size: 16px; }
            }

            &__partenaires { display: none !important; }
        }
    }
}