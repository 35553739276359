@import '../tools/_Mixin.scss';
@import '../tools/_Variables.scss';

/*--------------------------------------------------*\
                    contact media pc
\*--------------------------------------------------*/

.contact {
    position: relative;

    &__container {
        display: flex;
        margin-top: 300px;
        padding-bottom: 60px;
    }
    
    &__form {
        display: grid;
        gap: 15px;
        grid-column: 2;
        grid-row: 1/3;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 60px 60px 225px 70px 50px 30px 30px;
        
        padding: 35px 45px;
        min-width: 535px;
        
        background-color: white;
        box-shadow: 0 3px 6px $black;
        font-size: 20px;
        font-weight: 600;

        &__nom {
            grid-column: 1;
            grid-row: 1;
            & > input {
                width: 100%;
                height: 30px;
            }
        }

        &__prenom {
            grid-column: 2;
            grid-row: 1;
            & > input {
                width: 100%;
                height: 30px;
            }
        }

        &__email {
            grid-column: 1/3;
            grid-row: 2;
            & > input {
                width: 100%;
                height: 30px;
            }
        }

        &__message {
            grid-column: 1/3;
            grid-row: 3;
            & > textarea {
                width: 100%;
                height: 195px;
            }
        }

        &__submit {
            grid-column: 1/3;
            grid-row: 4;

            border-radius: 10px;
            width: 160px;
            height: 45px;

            border: 0 none;
            box-shadow: none;
            cursor: pointer;
            outline: none;

            align-self: center;
            background-color: $purple;
            color: $white;
            font-size: 20px;
            font-weight: 700;
            justify-self: center;
            transition-duration: 0.3s;

            &:hover {
                border: 1px solid $purple;
                background-color: $white !important;
                color: $purple;
            }
        }

        &__infos {
            grid-column: 1/3;
            grid-row: 5;
            
            border-top: 1px solid $black;
            padding-top: 10px;
            
            font-size: 20px;
            font-weight: 600;

            &__mail {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                grid-column: 1/3;
                grid-row: 6;
                
                &__icon {
                    width: 25px;
                    height: 19px;
                    margin-right: 30px;
                }

                &__txt {
                    font-size: 18px;
                    font-weight: 500;
                }
            }
            
            &__twitter {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                grid-column: 1/3;
                grid-row: 7;

                &__icon {
                    width: 26px;
                    height: 21px;
                    margin-right: 30px;
                }

                &__txt {
                    font-size: 18px;
                    font-weight: 500;
                    text-decoration: none;
                    color: $black;
                }
            }
        }
    }

    &__hero{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-left: 18.47%;
        
        text-align: center;

        &__title {
            font-size: 42px;
            font-weight: 700;
            margin-bottom: 20px;
        }

        &__subtitle {
            margin-bottom: 250px;
            font-size: 30px;
            font-weight: 400;
        }

        &__partenaires {
            margin-bottom: -35px;
        }
    }

    &__attributions {
        position: absolute;
        bottom: 5px;
        left: 5px;

        border: 1px solid transparent;
        
        color: $white;
        cursor: pointer;
    
        &:hover {
            border-bottom: 1px solid $white;
        }
    }

    &::after {
        position: absolute;
        z-index: -1;
        width: 44.60%;
        height: 100%;
        background: url('../images/form-contact.png') no-repeat;
        background-size: contain;
        background-position: bottom;
        content: '';
        bottom: 0;
        left:0;
    }
}

/*--------------------------------------------------*\
                contact media 1290px
\*--------------------------------------------------*/

@media (max-width: 1290px) {
    .contact__hero { margin-left: 5%;}
}

/*--------------------------------------------------*\
                contact media tablette
\*--------------------------------------------------*/

@media (max-width: 1024px) {
    .contact {
        position: relative;

        &__container {
            display: flex;
            flex-direction: column-reverse;
            margin-top: 180px;
            padding-bottom: 40px;
        }
        
        &__form {
            align-self: center !important;
            display: grid;
            gap: 15px;
            grid-column: 2;
            grid-row: 1/3;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 60px 60px 225px 70px 50px 30px 30px;
            
            padding: 35px 45px;
            width: 535px;
            
            background-color: white;
            box-shadow: 0 3px 6px $black;
            font-size: 20px;
            font-weight: 600;
        }

        &__hero{
            align-self: center !important;
            max-width: 515px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            margin-left: 0;
            
            text-align: center;

            &__subtitle {
                margin-bottom: 85px;
            }

            &__partenaires {
                display: none !important;
            }
        }
        
        &::after { width: 92%; }
    }
}

/*--------------------------------------------------*\
            contact media telephone 768px
\*--------------------------------------------------*/

@media (max-width: 1024px) {
    .contact { 
        &::after { width: 100%;} 
    }
}

/*--------------------------------------------------*\
            contact media telephone 375px
\*--------------------------------------------------*/

@media (max-width: 375px) {
    .contact {
        &__container {
            max-width: 375px;
            margin-top: 80px;
            padding-bottom: 30px;
        }
        
        &__form {
            grid-template-rows: 40px 40px 100px 70px 50px 30px 30px;
            gap: 8px;
            min-width: 350px;
            width: 350px;
            padding: 25px 15px;
            

            box-shadow: 0 1px 2px $black;
            font-size: 14px;

            &  input { height: 20px; }

            &  textarea { height: 90px; }

            &__submit {
                width: 100px;
                height: 40px;
                font-size: 14px;
            }

            &__infos {
                font-size: 14px;

                &__mail {
                    &__icon {
                        width: 20px;
                        height: 14px;
                    }

                    &__txt { font-size: 16px; }
                }
                
                &__twitter {
                    &__icon {
                        width: 21px;
                        height: 16px;
                    }

                    &__txt { font-size: 16px; }
                }
            }
        }

        &__hero{
                &__title {
                    font-size: 29px;
                    margin-bottom: 5px;
                }

            &__subtitle {
                max-width: 230px;
                font-size: 14px;
                margin-bottom: 50px;
            }
        }

        &__attributions {
            font-size: 14px;
        }
    }
}