@import '../tools/_Mixin.scss';
@import '../tools/_Variables.scss';
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
.overflowPhone {
    overflow-x: hidden;
}

body {
    font-family: 'Raleway', sans-serif;
    background-color: $white;
    color: $black;
}

.container {
    position: relative;
    max-width: $largeurEcran;
    min-width: 375px;
    margin: 0 auto;
    padding: 0 80px;
    @media (max-width: 768px) {
        padding: 0 40px;
    }
    @media (max-width: 375px) {
        padding: 0;
    }
}