@import '../tools/_Mixin.scss';
@import '../tools/_Variables.scss';

/*--------------------------------------------------*\
                    about media pc 
\*--------------------------------------------------*/

.about {
    &__container {
        position: relative;
        margin-top: 200px;
        padding: 0;
        display: grid;
        grid-template-columns: 215px 443px 1fr 215px;
        grid-template-rows: 1fr;
    }

    &__title {
        @include sectionTitle;
        position: absolute;
        margin-bottom: 70px;

        top: 54px;
        right: 212px;

        &::before {
            width: 674px;
            height: 234px;
            top: -75px;
            left: -20px;
            background: url(../images/titre/about.png) no-repeat;
        }
    }

    &__cube-left,
    &__cube-right {
        grid-column: 1;
        width: 215px;
        height: 215px;
        background-color: $purple;
    }

    &__cube-right { 
        position: absolute;
        grid-column: 4;
        bottom: -215px;
    }

    &__img {
        grid-column: 2;
        width: 443px;
        height: 443px;
        margin-top: 215px;
    }

    &__cube-center{
        position: relative;
        z-index: -1;
        grid-column: 3;
        height: auto;
        margin-top: 278px;
        margin-left: -221px;

        background-color: $purple;

        &__description {
                padding: 15px 30px 30px 245px;
                
                color: $white;
                line-height: 42px;
                font-size: 23px;
                font-weight: 600;
        }
    }
    
    
}

/*--------------------------------------------------*\
                about media tablette 
\*--------------------------------------------------*/

@media (max-width: 1024px) {
    .about {
        &__container {
            grid-template-columns: 134px 215px 1fr 134px;
            grid-template-rows: 1fr;
        }

        &__title {
            @include sectionTitle;
            position: absolute;
            margin-bottom: 70px;

            top: 40px;
            right: 110px;
            
            font-size: 46px;

            &::before {
                width: 460px;
                height: 172px;
                top: -42px;
                left: -15px;
                background-size: contain;
            }
        }
    
        &__cube-left,
        &__cube-right {
            width: 134px;
            height: 134px;
        }
    
        &__cube-right { 
            bottom: -134px; 
        }
    
        &__img {
            width: 215px;
            height: 215px;
            margin-top: 134px;
        }
    
        &__cube-center{  
            margin-top: 217px;
            margin-left: -107px;

            &__description {
                padding: 15px 30px 30px 130px;
            }
        }
    }
}

/*--------------------------------------------------*\
                about media telephone 768px
\*--------------------------------------------------*/

@media (max-width: 768px) {
    .about {
        &__container {
            margin-top: 100px;
            padding: 0 40px;
            grid-template-columns: 215px 1fr;
        }

        &__title {
            top: 30px;
            right: 36px;
            font-size: 39px;

            &::before {
                width: 300px;
                height: 80px;
                top: -27px;
                left: -8px;
            }
        }
    
        &__cube-left,
        &__cube-right { display: none; }
    
        &__img {
            grid-column: 1;
            margin-top: 100px; 
        }
    
        &__cube-center{
            grid-column: 2;
            margin-top: 180px;
            margin-left: -107px;

            &__description {
                padding: 30px 15px 30px 130px;
            }
        }
    }
}

/*--------------------------------------------------*\
                about media telephone 375px
\*--------------------------------------------------*/

@media (max-width: 375px) {
    .about {
        &__container {
            margin-top: 55px;
            padding: 0;
            grid-template-columns: 150px 1fr;
        }

        &__title {
            left: 12px;
            font-size: 29px;

            &::before { left: -12px; }
        }
    
        &__img {
            width: 150px;
            height: 150px;
        }
    
        &__cube-center{
            &__description {
                padding: 85px 30px 20px 30px;
                font-size: 16px;
                line-height: 25px;
            }
        }
    }
}