@import '../tools/_Mixin.scss';
@import '../tools/_Variables.scss';

/*--------------------------------------------------*\
                   methode media pc
\*--------------------------------------------------*/

.methode {
    &__container {
        position: relative;
        margin-top: 250px;
        
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    &__title {
        @include sectionTitle;
        align-self: flex-start;
        margin: 0 12px 90px 0;
        border-top: 50px solid transparent;

        &::before {
            width: 1105px;
            height: 164px;
            top: -75px;
            left: -22px;
            background: url(../images/titre/methode.svg) no-repeat;
        }
    }

    &__box {
        max-width: 1050px;
        display: flex; 
        flex-wrap: wrap;
        justify-content: center;

        &__card {
            width: 240px;
            height: 320px;
            margin: 25px 5%;

            background-color: #F5F5F5;
            border-radius: 30px;
            box-shadow: 0 3px 6px $black;
            
            text-align: center;
            transition: all 0.3s cubic-bezier(.164, .317, .38, .767);
            transform-style: preserve-3d;
            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;

            &__img {
                
                &__space1 { 
                    width: 201px;
                    height: auto;          
                    margin-top: 24px; 
                }

                &__space2 { 
                    width: 201px;
                    height: auto;          
                    margin-top: 14px; 
                }
            }

            &__title {
                margin-top: 5px;
                font-size: 30px;
                font-weight: 700;
                color: $black;
            }

            &__description {
                margin-top: 22px;
                max-width: 210px;
                font-size: 14px;
                line-height: 21px;
                color: $gray;
                margin-left: auto;
                margin-right: auto;
            }

            &:hover {
                transform-style: preserve-3d;
                transform: translateY(-20px) rotateZ(2deg) !important;
            }
        }

        &::after {
            position: absolute;
            z-index: -1;
            content: '';
            width: $largeurEcran;
            
            top: 280px;
            bottom: 60px;
            left: 0;
            
            background-color: $purple;
        }
    }
} 

/*--------------------------------------------------*\
                   methode media tablette 
\*--------------------------------------------------*/

@media (max-width: 1024px) {
    .methode {
        &__container { margin-top: 100px; }

        &__title {
        margin-left: 10px;
        border-top: 40px solid transparent;
        font-size: 46px;
    
            &::before {
                width: 615px;
                height: 90px;
                top: -40px;
                left: -15px;
                background-size: contain;
            }
        } 
    }
}

/*--------------------------------------------------*\
                   methode media telephone 768px
\*--------------------------------------------------*/

@media (max-width: 768px) {
    .methode {
        &__container { margin-top: 100px; }

        &__title {
            border-top: 25px solid transparent;
            font-size: 39px;
    
            &::before {
                width: 485px;
                height: 70px;
                top: -25px;
            }
        }
    } 
}

/*--------------------------------------------------*\
                methode media telephone 375px
\*--------------------------------------------------*/

@media (max-width: 375px) {
    .methode {

        &__title {
            margin: 0 0 50px 12px;
            border-top: 25px solid transparent;
            font-size: 29px;
    
            &::before {
                width: 350px;
                height: 60;
                top: -20px;
            }
        }

        &__box {
            &:after { top: 175px; }
        }
    } 
}